import { StateCreator } from 'zustand';

import { ActionsStoreSliceProps } from './actionsSlice.types.ts';

import { ActionSortCriteriaEnum } from '@/modules/Actions/types/actions.types.ts';

export const createActionsSlice: StateCreator<ActionsStoreSliceProps> = (set, get) => ({
  actionFilters: {
    archive: {
      date: new Date(),
      searchKey: '',
      subCategories: [],
    },
    equipment: [],
    safety: [],
    sort: ActionSortCriteriaEnum.MOST_IMPORTANT,
    system: [],
    zone: [],
  },
  actions: [],
  alerts: [],
  alertsLoading: true,
  archivedActions: [],
  archivedAlerts: [],
  archivedAlertsLoading: true,
  metrics: [],
  metricsLoading: false,
  moveAlertToArchive: (alertResourceName: string) => {
    const { alerts, archivedAlerts } = get();
    const alert = alerts.find(alert => alert.alertResName === alertResourceName);

    if (alert) {
      const index = alerts.indexOf(alert);
      const alertsBuffer = [...alerts];
      const archivedAlertsBuffer = [...archivedAlerts];

      archivedAlertsBuffer.push(...alertsBuffer.splice(index, 1));

      set({
        alerts: alertsBuffer,
        archivedAlerts: archivedAlertsBuffer,
      });
    }
  },
  setActionFilters: filters => {
    const existingFilters = get().actionFilters;
    set({ actionFilters: { ...existingFilters, ...filters } });
  },
  setActions: dataOrSetter => {
    const actions = get().actions;
    const modifiedActions = Array.isArray(dataOrSetter) ? dataOrSetter : dataOrSetter(actions);
    set({ actions: modifiedActions, alertsLoading: false });
  },
  setAlerts: dataOrSetter => {
    const modifiedAlerts = Array.isArray(dataOrSetter) ? dataOrSetter : dataOrSetter(get().alerts);
    set({ alerts: modifiedAlerts, alertsLoading: false });
  },
  setArchivedActions: dataOrSetter => {
    const actions = get().archivedActions;
    const modifiedActions = Array.isArray(dataOrSetter) ? dataOrSetter : dataOrSetter(actions);
    set({ alertsLoading: false, archivedActions: modifiedActions });
  },
  setArchivedAlerts: dataOrSetter => {
    const modifiedAlerts = Array.isArray(dataOrSetter)
      ? dataOrSetter
      : dataOrSetter(get().archivedAlerts);
    set({ archivedAlerts: modifiedAlerts, archivedAlertsLoading: false });
  },
  setArchivedAlertsLoading: isLoading => {
    set({ archivedAlertsLoading: isLoading });
  },
  setMetrics: (newMetricsData, isLoading) => {
    set({
      metrics: newMetricsData,
      metricsLoading: isLoading || false,
    });
  },
  setWsAlertAvailable: available => {
    set({ wsAlertAvailable: available });
  },
  wsAlertAvailable: false,
});
