import { FC } from 'react';

import { SUSPENSE_LOADER_BASE64_IMAGE } from '@/shared/components/interactions/Loader/SuspenseLoader/SuspenseLoader.constants.ts';

export const SuspenseLoader: FC = () => {
  return (
    <div
      className="fixed left-0 top-0 z-[9999] flex h-screen w-screen items-center justify-center bg-blue-600 bg-[center_48%] bg-no-repeat"
      style={{
        backgroundImage: `url(${SUSPENSE_LOADER_BASE64_IMAGE})`,
      }}
    >
      <div className="mt-[100px] size-10 animate-spin rounded-full border-4 border-[#ddeaff] border-t-blue-600" />
    </div>
  );
};
