import { FC, HTMLAttributes } from 'react';

import { AssetBody } from '@/shared/types/assets/assets.types.ts';
import { TagBody } from '@/shared/types/assets/tags.types.ts';
import { RolesEnum } from '@/shared/types/global/enums.ts';
import { GeofencesProps } from '@/shared/types/global/geofences.types.ts';
import { ReadersProps } from '@/shared/types/global/readers.types.ts';
import { Reservation } from '@/shared/types/global/reservations.types.ts';

export interface AlertProps {
  accountResName: string;
  actionUrls?: string;
  active: boolean;
  alertCategory: ActionCategoryEnum;
  alertDescription: string;
  alertName: string;
  alertResName: string;
  alertSeverity: ActionSeverityEnum;
  assetResName?: string;
  attribute: ActionSubCategoryEnum;
  deviceResName?: string;
  metadata?: string;
  siteResName: string;
  timestamp: number;
}

export interface Action extends AlertProps {
  asset?: Pick<AssetBody, 'assetName' | 'assetResName'>;
  geofence?: Pick<GeofencesProps, 'geofenceName' | 'geofenceResName'>;
  i18n?: { actions: string[]; content: string; subCategory: string; title: string };
  proximityEquipmentAsset?: Pick<AssetBody, 'assetName' | 'assetResName'>;
  proximityPersonAsset?: Pick<
    AssetBody,
    'assetName' | 'assetResName' | 'categoryTreeResName' | 'extraProperties'
  >;
  reader?: Pick<ReadersProps, 'readerName' | 'readerResName'>;
  reservation?: Pick<Reservation, 'reservationPeriod' | 'reservationResName' | 'user'>;
  tag?: Pick<TagBody, 'tagName' | 'tagResName'>;
}

export interface AlertMetaData {
  assetAResName?: string;
  assetBResName?: string;
  assetResName?: string;
  deviceResName?: string;
  geofenceResName?: string;
  proximityZone?: string;
  reservationResName?: string;
}

export interface AlertResponse {
  page: {
    forward?: boolean;
    hasNext: boolean;
    pageEndResPointer: string;
    pageItemsCount: number;
    pageStartResPointer: string;
  };
  results: AlertProps[];
}

export interface ActionRule {
  readonly actions: ActionHandlerEnum[];
  readonly category: ActionCategoryEnum;
  readonly categoryIcon: FC<HTMLAttributes<SVGElement>>;
  readonly i18n: {
    readonly actions: () => string[];
    readonly content: () => string;
    readonly subCategory: () => string;
    readonly title: () => string;
  };
  readonly name: ActionNameEnum;
  readonly roles: { [K in RolesEnum]: boolean };
  readonly severity: ActionSeverityEnum;
  readonly severityTheme: ActionSeverityTheme;
  readonly subCategory: ActionSubCategoryEnum;
}

export interface ActionI18NData {
  assetName?: string;
  closingTime?: string;
  firmName?: string;
  personName?: string;
  reservationDay?: string;
  reservationFirmName?: string;
  timeRange?: string;
  timestamp?: string;
  zoneName?: string;
}

export enum ActionNameEnum {
  BATTERY_CRITICAL = 'device.battery.critical',
  BATTERY_DEAD = 'device.battery.dead',
  BATTERY_LOW = 'device.battery.low',
  EQUIPMENT_ENTERED_RED_ZONE = 'equipment.redzone.entered',
  EQUIPMENT_IN_USE_BY_WRONG_SUB_FIRM = 'equipment.in.use.by.wrong.subcontractor.firm',
  EQUIPMENT_IN_USE_WITHOUT_RESERVATION = 'equipment.in.use.without.reservation',
  EQUIPMENT_NOT_UTILIZED_ON_WORKDAY = 'equipment.utilization.notutilizedworkday',
  EQUIPMENT_REDZONE_ACTIVATED = 'equipment.redzone.activated',
  EQUIPMENT_REDZONE_RELEASED = 'equipment.redzone.released',
  EQUIPMENT_RESERVATION_APPROVED = 'equipment.reservation.approved',
  EQUIPMENT_RESERVATION_APPROVED_WITH_CHANGES = 'equipment.reservation.subcontractor.request.approved.with.changes',
  EQUIPMENT_RESERVATION_CANCELLED = 'equipment.reservation.cancelled',
  EQUIPMENT_RESERVATION_CONFLICT = 'equipment.reservation.conflict',
  EQUIPMENT_RESERVATION_DRAFT_EXPIRED = 'reservation.draft.expired',
  EQUIPMENT_RESERVATION_EDITED = 'equipment.reservation.edited',
  EQUIPMENT_RESERVATION_NEW = 'equipment.reservation.new',
  EQUIPMENT_RESERVATION_NO_UPCOMING = 'equipment.reservation.no_upcoming',
  EQUIPMENT_RESERVATION_REQUEST = 'equipment.reservation.subcontractor.request',
  EQUIPMENT_RESERVATION_REQUEST_EXPIRED = 'reservation.request.expired',
  EQUIPMENT_RESERVATION_SUB_REQUEST = 'equipment.reservation.sub.request',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_APPROVED = 'equipment.reservation.subcontractor.request.approved',
  EQUIPMENT_RESERVATION_SUBCONTRACTOR_REQUEST_REJECTED = 'equipment.reservation.subcontractor.request.rejected',
  EQUIPMENT_RESERVATION_WORKER_REQUEST = 'equipment.reservation.worker.request',
  EQUIPMENT_RESERVED_NOTINUSE = 'equipment.reserved.notinuse',
  EQUIPMENT_STILL_IN_USE_AFTER_RESERVATION_ENDS = 'equipment.still.in.use.after.reservation.ends',
  PROXIMITY_REDZONE_EQUIPMENT_MOVED = 'proximity.rule.redzone_equipment_overhead',
  PROXIMITY_REDZONE_EQUIPMENT_OVERHEAD = 'proximity.rule.redzone_equipment_moved',
  PROXIMITY_REDZONE_TRIGGERED = 'proximity.rule.redzone',
  READER_OFFLINE = 'device.reader.offline',
  READER_ONLINE = 'new.reader.online',
  TAG_LEFT_SITE = 'tag.left.site',
  TAG_OFFLINE = 'device.tag.offline',
  TAG_ONLINE = 'new.tag.online',
  WORKER_REDZONE_ENTERED = 'worker.redzone.entered',
  WORKERS_LEFT_SITE_FOR_DAY = 'workers.left.site.for.day',
  WORKERS_STILL_ON_SITE_AFTER_WORKING_HOURS = 'workers.still.on.site.after.working.hours',
}

export enum ActionSeverityEnum {
  DEFAULT = 'default',
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium',
}

export enum ActionCategoryEnum {
  EQUIPMENT = 'equipment',
  RESERVATION = 'reservation',
  SAFETY = 'safety',
  SYSTEM = 'system',
  ZONE = 'zone',
}

export enum ActionSubCategoryEnum {
  AFTER_HOURS = 'after-hours',
  BEHIND = 'behind',
  DANGER = 'danger',
  EMERGENCY = 'emergency',
  READER = 'reader',
  RED_ZONE = 'red_zone',
  RESERVATION = 'reservation',
  TAG = 'tag',
  UPDATE = 'update',
  UTILIZATION = 'utilization',
  WORK_ZONE = 'work_zone',
  ZONE = 'zone',
}

export enum ActionHandlerEnum {
  HIDE = 'hide',
  LAST_LOCATION = 'last-location',
  REVIEW_CONFLICT = 'review-conflict',
  REVIEW_REQUEST = 'review-request',
  VIEW_ON_MAP = 'view-on-map',
  VIEW_RESERVATION_CALENDAR = 'view-reservation-calendar',
  VIEW_UTILIZATION = 'view-utilization',
}

export interface ActionSeverityTheme {
  bgClass: string;
  dateClass: string;
  fgClass: string;
  iconClass: string;
  textClass: string;
}

export interface MetricProps {
  accountResName: string;
  alertResName: string; // TODO: Determine if this is valid for metrics
  deviceResName: string;
  metricName: string;
  metricValue: string;
  siteResName: string;
  timestamp: number;
}

export interface MetricResponse {
  metrics: MetricProps[];
}

export interface ActionFilters {
  archive: {
    date: Date;
    searchKey: string;
    subCategories: ActionSubCategoryEnum[];
  };
  equipment: ActionSubCategoryEnum[];
  safety: ActionSubCategoryEnum[];
  sort: ActionSortCriteriaEnum;
  system: ActionSubCategoryEnum[];
  zone: ActionSubCategoryEnum[];
}

export enum ActionSortCriteriaEnum {
  MOST_IMPORTANT = 'most-important',
  MOST_RECENT = 'most-recent',
}
