import { ReactNode } from 'react';

import { StateCreator } from 'zustand';

import { generateId } from '@/shared/utils/helpers';

import { ActionData, PopupSliceProps } from '@/shared/store/popup/popupSlice.types.ts';

const INITIAL_DATA = {
  backdrop: false,
  children: null,
  clientAxis: { clientX: 0, clientY: 0 },
  closeOnSelect: false,
  currentTarget: null,
  customData: null,
  disableClickOutside: false,
  isOpen: false,
  popover: { height: 0, width: 0 },
  position: 'right',
};

const renderPopupData = (payload: ReactNode, meta: Partial<ActionData>) => {
  const currentTarget =
    meta?.event?.currentTarget instanceof HTMLElement
      ? meta?.event?.currentTarget.getBoundingClientRect()
      : null;

  return {
    ...INITIAL_DATA,
    backdrop: meta?.backdrop ?? INITIAL_DATA.backdrop,
    children: payload,
    closeOnSelect: meta?.closeOnSelect ?? INITIAL_DATA.closeOnSelect,
    customData: meta?.customData ?? INITIAL_DATA.customData,
    disableClickOutside: meta?.disableClickOutside ?? INITIAL_DATA.disableClickOutside,
    id: meta.id ?? generateId(),
    isOpen: true,
    popover: meta.popover ?? INITIAL_DATA.popover,
    position: meta?.position ?? INITIAL_DATA.position,
    ...(currentTarget && {
      currentTarget: meta?.event?.currentTarget ?? INITIAL_DATA.currentTarget,
    }),
    ...(!currentTarget && {
      clientAxis: {
        clientX: meta?.event?.clientX,
        clientY: meta?.event?.clientY,
      },
    }),
  };
};

export const createPopupSlice: StateCreator<PopupSliceProps> = (set, get) => ({
  // POPUPS LIST
  popups: [],

  // SET POPUP
  setPopup: (payload, meta) => {
    const incomingPopup = renderPopupData(payload, meta);

    set(state => ({
      popups: [...state.popups.filter(popup => popup.id !== meta.id), incomingPopup],
    }));
  },

  // CLOSE CURRENT POPUP BY ID
  closePopup: id =>
    set(state => ({
      popups: state.popups.filter(popup => popup.id !== id),
    })),

  // CLOSE CURRENT POPUP BY ID
  closeActivePopups: () =>
    set({
      popups: [],
    }),

  // SET POPUP SIZE BY ID
  setPopoverSize: (id, width, height) => {
    const modifiedPopup = get().popups.map(popup => {
      if (popup.id === id) {
        return {
          ...popup,
          popover: {
            height,
            width,
          },
        };
      }
      return popup;
    });

    set({
      popups: modifiedPopup,
    });
  },
});
