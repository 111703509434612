import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createActionsSlice } from '@/shared/store/actions/actionsSlice.ts';
import { createAssetsSlice } from '@/shared/store/assets/assetsSlice.ts';
import { createAuthStatusSlice } from '@/shared/store/auth/authSlice.ts';
import { createModalSlice } from '@/shared/store/modal/modalSlice.ts';
import { createPopupSlice } from '@/shared/store/popup/popupSlice.ts';
import { createUISlice } from '@/shared/store/ui/uiSlice.ts';
import { LS_KEYS } from '@/shared/utils/constants.ts';

import { ActionsStoreSliceProps } from '@/shared/store/actions/actionsSlice.types.ts';
import { AssetsSliceProps } from '@/shared/store/assets/assetsSlice.types.ts';
import { AuthStatusSliceProps } from '@/shared/store/auth/authSlice.types.ts';
import { ModalSliceProps } from '@/shared/store/modal/modalSlice.types.ts';
import { PopupSliceProps } from '@/shared/store/popup/popupSlice.types.ts';
import { UiSliceProps } from '@/shared/store/ui/uiSlice.types.ts';

export interface AppStoreProps
  extends ActionsStoreSliceProps,
    AuthStatusSliceProps,
    AssetsSliceProps,
    UiSliceProps,
    ModalSliceProps,
    PopupSliceProps {}

export const useGlobalStore = create<AppStoreProps>()(
  devtools(
    persist(
      (...store) => ({
        ...createActionsSlice(...store),
        ...createAuthStatusSlice(...store),
        ...createAssetsSlice(...store),
        ...createUISlice(...store),
        ...createModalSlice(...store),
        ...createPopupSlice(...store),
      }),
      {
        name: LS_KEYS.UI_STORE,
        partialize: state => ({
          /**
           * This is a list of all the keys that we want to persist in the local storage.
           * */
          accountName: state.accountName,
          currency: state.currency,
          currentLanguage: state.currentLanguage,
          displayTimeZone: state.displayTimeZone,
          distance: state.distance,
          endTime: state.endTime,
          floor: state.floor,
          floorsList: state.floorsList,
          isCanvasPopupsOpen: state.isCanvasPopupsOpen,
          isCellCopyTipActive: state.isCellCopyTipActive,
          isShowInactive: state.isShowInactive,
          mapFilter: state.mapFilter,
          openedViewPanel: state.openedViewPanel,
          site: state.site,
          siteCalendars: state.siteCalendars,
          sitePolicies: state.sitePolicies,
          sitesList: state.sitesList,
          sitesListLoading: state.sitesListLoading,
          siteTimeZone: state.siteTimeZone,
          startTime: state.startTime,
          systemView: state.systemView,
          tagsId: state.tagsId,
          timeFormat: state.timeFormat,
          userSettings: state.userSettings,
        }),
        version: 1,
      },
    ),
    { name: 'Global Store' },
  ),
);
